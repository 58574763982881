import React from 'react';
import { connect } from 'react-redux';
import { toggleAddModal } from '../../redux/actions/subSmaster/toggleModals';
import { addSubSmaster} from '../../redux/actions/subSmaster/addSubSmaster';
import { message, Modal, Form, Input } from 'antd';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class AddSubSmasterModal extends React.Component {

    constructor(props){
        super(props);
        this.form=React.createRef();
    }
    onFinish=async (values)=>{
        await this.props.onAdd(values)
        if(this.props.error){
            message.error(this.props.error);
        }
        if(this.props.message){
            message.success(this.props.message);
            this.form.current.resetFields();
            this.props.onClose();
        }
    }
    render() {
        return (<Modal
            title="Add Sub Smaster"
            key="add-sub-smaster"
            centered
            visible={this.props.visible}
            okButtonProps={{ form: 'add-ss-form', key: 'submit', htmlType: 'submit' }}
            confirmLoading={this.props.loading}
            onCancel={this.props.onClose}
            okText="Save"
            loading={this.props.loading}
        >
            <Form
                {...layout}
                ref={this.form}
                style={{ margin: 25 }}
                id="add-ss-form"
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
            >
                 <Form.Item
                name="name"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Require!',
                    }
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="username"
                label="Sub Smaster ID"
                rules={[
                    {
                        required: true,
                        message: 'Must be 2 characters!',
                        max:2
                    }
                ]}
                hasFeedback
            >
                <Input prefix={this.props.user?.username}/>
            </Form.Item>
            <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                    {
                        required: true,
                        message: 'Require!',
                    }
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: 'Minimum eight characters',
                        min:8,
                        max:40
                    }
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>
            </Form>
        </Modal>);
    }
}

const mapStateToProps = state => ({
    loading: state.subSmaster.loading,
    error: state.subSmaster.error,
    message: state.subSmaster.message,
    visible: state.subSmaster.addSubSmasterVisible,
    user:state.user.current
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(toggleAddModal()),
    onAdd: (obj) => dispatch(addSubSmaster(obj))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSubSmasterModal);
