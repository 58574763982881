import React from 'react';
import { connect } from 'react-redux';
import { Form, InputNumber, message, Modal } from 'antd';
import * as actionTypes from '../../redux/actions/master/actionTypes';
import { updateShareDetail } from '../../redux/actions/master/updateMaster';

class UpdateShareDetailModal extends React.Component {
      constructor(props) {
            super(props);
            this.form = React.createRef();
      }

      onFinish = async (values) => {
            await this.props.onUpdate({
                  id: this.props.master?.id,
                  shareDetailId: this.props.master?.shareDetail?.id,
                  sharePercentage: values.sharePercentage,
                  threeDSharePercentage: values.threeDSharePercentage,
                  fourDSharePercentage: values.fourDSharePercentage,
            });
            if (this.props.error) {
                  message.error(this.props.error);
            }
            if (this.props.message) {
                  message.success(this.props.message);
                  this.form.current.resetFields();
                  this.props.onClose();
            }
      }
      onFinishFailed = (errors) => {
            console.log(errors)
      }
      handleCancel = () => {
            this.props.onClose();
      }

      render() {
            return (
                  <Modal
                        title="Update Share Detail"
                        key={this.props.master?.id}
                        centered
                        visible={this.props.visible}
                        okButtonProps={{ form: 'update-sd-form', key: 'submit', htmlType: 'submit' }}
                        confirmLoading={this.props.loading}
                        onCancel={this.handleCancel}
                        okText="Save"
                        loading={this.props.loading}
                  >
                        <Form
                              layout="vertical"
                              ref={this.form}
                              style={{ margin: 25 }}
                              id="update-sd-form"
                              name="basic"
                              initialValues={{
                                    sharePercentage: this.props.master?.shareDetail?.sharePercentage,
                                    threeDSharePercentage: this.props.master?.shareDetail?.threeDSharePercentage,
                                    fourDSharePercentage: this.props.master?.shareDetail?.fourDSharePercentage,
                              }}
                              onFinish={this.onFinish}
                              onFinishFailed={this.onFinishFailed}
                        >
                              <Form.Item
                                    name="sharePercentage"
                                    label="Share Percentage"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Require!',
                                          }
                                    ]}
                                    hasFeedback
                              >
                                    <InputNumber style={{ width: 150 }} />
                              </Form.Item>
                              <Form.Item
                                    name="threeDSharePercentage"
                                    label="Three D Share Percentage"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Require!',
                                          }
                                    ]}
                                    hasFeedback
                              >
                                    <InputNumber style={{ width: 150 }} />
                              </Form.Item>
                              <Form.Item
                                    name="fourDSharePercentage"
                                    label="Four D Share Percentage"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Require!',
                                          }
                                    ]}
                                    hasFeedback
                              >
                                    <InputNumber style={{ width: 150 }} />
                              </Form.Item>
                        </Form>
                  </Modal>
            );
      }
}

const mapStateToProps = state => ({
      visible: state.master.updateShareDetailVisible,
      loading: state.master.loading,
      error: state.master.error,
      message: state.master.message,
      master: state.master.one
})

const mapDispatchToProps = dispatch => ({
      onUpdate: (info) => dispatch(updateShareDetail(info)),
      onClose: () => dispatch({
            type: actionTypes.TOGGLE_SHARE_DETAIL_UPDATE
      }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateShareDetailModal);