import React from 'react';
import { connect } from 'react-redux';
import { toggleResetPassword } from '../../redux/actions/subSmaster/toggleModals';
import { resetPassword } from '../../redux/actions/subSmaster/resetPassword';
import { message, Modal, Form, Input } from 'antd';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class ResetSubSmasterPasswordModal extends React.Component {

    constructor(props){
        super(props);
        this.form=React.createRef();
    }
    onFinish=async (values)=>{
        await this.props.onReset({
            id:this.props.one?.id,
            password:values.password
        })
        if(this.props.error){
            message.error(this.props.error);
        }
        if(this.props.message){
            message.success(this.props.message);
            this.form.current.resetFields();
            this.props.onClose();
        }
    }
    render() {
        return (<Modal
            title="Reset Sub Smaster Password"
            key={`${this.props.one?.id}reset-sub-smaster`}
            centered
            visible={this.props.visible}
            okButtonProps={{ form: 'reset-ss-form', key: 'submit', htmlType: 'submit' }}
            confirmLoading={this.props.loading}
            onCancel={this.props.onClose}
            okText="Save"
            loading={this.props.loading}
        >
            <Form
                {...layout}
                ref={this.form}
                style={{ margin: 25 }}
                id="reset-ss-form"
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
            >
                <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Minimum eight characters',
                            min: 8,
                            max: 40
                        }
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                            min: 8,
                            max: 40
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>);
    }
}

const mapStateToProps = state => ({
    loading: state.subSmaster.loading,
    error: state.subSmaster.error,
    message: state.subSmaster.message,
    one: state.subSmaster.one,
    visible: state.subSmaster.resetPasswordVisible
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(toggleResetPassword()),
    onReset: ({ id, password }) => dispatch(resetPassword({ id, password }))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetSubSmasterPasswordModal);
