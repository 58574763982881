import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import ReduxThunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import masterReducer from './reducers/masterReducer';
import transactionReducer from './reducers/transactionReducer';
import reportReducer from "./reducers/reportReducer";
import betPatternReducer from "./reducers/betPatternReducer";
import userReducer from "./reducers/userReducer";
import subSmasterReducer from "./reducers/subSmasterReducer";
import agreementReducer from "./reducers/agreementReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    master:masterReducer,
    transaction:transactionReducer,
    report:reportReducer,
    betPattern:betPatternReducer,
    user:userReducer,
    subSmaster:subSmasterReducer,
    agreement:agreementReducer
  });
  
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(ReduxThunk))
  );
  
  export default store;