import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
    ADD_SUB_SMASTER_START,
    ADD_SUB_SMASTER_SUCCESS,
    ADD_SUB_SMASTER_FAIL,
    SUB_SMASTER_UPDATE_START,
    SUB_SMASTER_UPDATE_SUCCESS,
    SUB_SMASTER_UPDATE_FAIL,
    SUB_SMASTER_LIST_START,
    SUB_SMASTER_LIST_SUCCESS,
    TOGGLE_SUB_SMASTER_ADD_MODAL,
    TOGGLE_SUB_SMASTER_UPDATE_MODAL,
    TOGGLE_SUB_SMASTER_RESET_PW_MODAL,
    RESET_SUB_SMASTER_PASSWORD_START,
    RESET_SUB_SMASTER_PASSWORD_SUCCESS,
    RESET_SUB_SMASTER_PASSWORD_FAIL
} from '../actions/subSmaster/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    all:[],
    one:null,
    addSubSmasterVisible:false,
    updateSubSmasterVisible:false,
    resetPasswordVisible:false,
}

const addSubSmaster=(state,action)=>{
    return update(state,{
        loading:false,
        message:'added sub smaster successfully',
        all:[
            ...state.all,
            action.payload
        ]
    })
}

const updateSubSmaster=(state,{payload})=>{
    return update(state,{
        loading:false,
        message:'updated sub smaster successfully',
        all:state.all.map(obj=>obj.id===payload?.id?payload:obj)
    })
}

const subSmasterList=(state,action)=>{
    return update(state,{
        loading:false,
        all:action.payload
    })
}

const resetPassword=(state,action)=>{
    return update(state,{
        loading:false,
        message:'reset password successfully'
    })
}

const subSmasterReducer=(state=initialState,action)=>{
    switch(action.type){
        case ADD_SUB_SMASTER_START:
            return loading(state,action);
        case ADD_SUB_SMASTER_SUCCESS:
            return addSubSmaster(state,action);
        case ADD_SUB_SMASTER_FAIL:
            return error(state,action);
        case SUB_SMASTER_UPDATE_START:
            return loading(state,action);
        case SUB_SMASTER_UPDATE_SUCCESS:
            return updateSubSmaster(state,action);
        case SUB_SMASTER_UPDATE_FAIL:
            return error(state,action);
        case SUB_SMASTER_LIST_START:
            return loading(state,action);
        case SUB_SMASTER_LIST_SUCCESS:
            return subSmasterList(state,action);
        case RESET_SUB_SMASTER_PASSWORD_START:
            return loading(state,action);
        case RESET_SUB_SMASTER_PASSWORD_SUCCESS:
            return resetPassword(state,action);
        case RESET_SUB_SMASTER_PASSWORD_FAIL:
            return error(state,action);
        case TOGGLE_SUB_SMASTER_ADD_MODAL:
            return update(state,{
                addSubSmasterVisible:!state.addSubSmasterVisible
            });
        case TOGGLE_SUB_SMASTER_UPDATE_MODAL:
            return update(state,{
                updateSubSmasterVisible:!state.updateSubSmasterVisible,
                one:action.payload
                });
        case TOGGLE_SUB_SMASTER_RESET_PW_MODAL:
            return update(state,{
                resetPasswordVisible:!state.resetPasswordVisible,
                one:action.payload
            })
        default:
            return state;
    }
}

export default subSmasterReducer;