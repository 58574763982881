export const CREATE_TRANSACTION_START='CREATE_TRANSACTION_START';
export const CREATE_TRANSACTION_SUCCESS='CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAIL='CREATE_TRANSACTION_FAIL';

export const TOGGLE_TRANSACTION_ADD_MODAL='TOGGLE_TRANSACTION_ADD_MODAL';

export const GET_SMASTER_TRANSACTION_START='GET_SMASTER_TRANSACTION_START';
export const GET_SMASTER_TRANSACTION_SUCCESS='GET_SMASTER_TRANSACTION_SUCCESS';
export const GET_SMASTER_TRANSACTION_FAIL='GET_SMASTER_TRANSACTION_FAIL';

export const GET_SM_TRANSACTION_DETAIL_START='GET_SM_TRANSACTION_DETAIL_START';
export const GET_SM_TRANSACTION_DETAIL_SUCCESS='GET_SM_TRANSACTION_DETAIL_SUCCESS';
export const GET_SM_TRANSACTION_DETAIL_FAIL='GET_SM_TRANSACTION_DETAIL_FAIL';

export const GET_MASTER_TRANSACTION_START='GET_MASTER_TRANSACTION_START';
export const GET_MASTER_TRANSACTION_SUCCESS='GET_MASTER_TRANSACTION_SUCCESS';
export const GET_MASTER_TRANSACTION_FAIL='GET_MASTER_TRANSACTION_FAIL';

export const GET_MASTER_TRANSACTION_DETAIL_START='GET_MASTER_TRANSACTION_DETAIL_START';
export const GET_MASTER_TRANSACTION_DETAIL_SUCCESS='GET_MASTER_TRANSACTION_DETAIL_SUCCESS';
export const GET_MASTER_TRANSACTION_DETAIL_FAIL='GET_MASTER_TRANSACTION_DETAIL_FAIL';