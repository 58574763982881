import React from 'react';
import { Row, Col, Form, Button, Input, InputNumber, message, Switch } from 'antd';
import { connect } from 'react-redux';
import { createMaster } from '../../redux/actions/master/createMaster';

const COLUMN_WIDTH = {
      lg: 12,
      md: 12,
      sm: 24,
      xs: 24
}

const layout = {
      wrapperCol: { span: 24 },
};
const commmissionLayout = {
      labelCol: {
            span: 10
      },
      wrapperCol: {
            span: 24
      }
}

class CreateMaster extends React.Component {
      constructor(props) {
            super(props);
            const lastCreatedMaster = localStorage.getItem('master');
            this.master = JSON.parse(lastCreatedMaster);
            this.form = React.createRef();
            this.state = {
                  commission: this.master?.commissions || [
                        {
                              matchCount: 0,
                              betCommission: 0,
                              winCommission: 0
                        }
                  ]
            }
            this.mixBetComInitials = {};
            this.props.smaster?.commissions.map((value, index) => {
                  this.mixBetComInitials[`betCommission${index}`] = this.state.commission[index]?.betCommission
            });
      }
      onFinish = async (values) => {
            const { singleBetCommission: {
                  winCommission,
                  highWinCommission
            } } = this.props.smaster;
            const request = {
                  name: values.name,
                  username: values.username,
                  password: values.password,
                  mobile: values.mobile,
                  twoThreeAllowed: true,
                  twoDCommission: 0,
                  threeDCommission: values.threeDCommission || 0,
                  fourDCommission:values.fourDCommission || 0,
                  betLimitation: {
                        maxForSingleBet: values.maxForSingleBet,
                        maxForMixBet: values.maxForMixBet,
                        maxForThreeD: values.maxForThreeD,
                        maxForFourD:values.maxForFourD,
                  },
                  shareDetail: {
                        sharePercentage: values.sharePercentage,
                        threeDSharePercentage: values.threeDSharePercentage,
                        fourDSharePercentage: values.fourDSharePercentage,
                  },
                  singleBetCommission: {
                        betCommission: values.betCommission,
                        highBetCommission: values.highBetCommission,
                        winCommission,
                        highWinCommission
                  },
                  commissions: this.props.smaster?.commissions.map((com, i) => {
                        return {
                              ...com,
                              betCommission: values[`betCommission${i}`]
                        }
                  })
            }
            await this.props.onCreate(request);
            if (this.props.message) {
                  message.success(this.props.message);
                  localStorage.setItem('master', JSON.stringify(request));
                  this.form.current.resetFields();
            }
            if (this.props.error) {
                  message.error(this.props.error)
            }
      }
      onFinishFailed = (errors) => {
            console.log(errors);
      }
      render() {
            console.log('this.smaster?.betLimitation?.maxForThreeD??0', this.props.smaster?.betLimitation?.maxForThreeD ?? 0);
            return (
                  <Row>
                        <Col xxl={{ span: 18 }}
                              xl={{ span: 18 }}
                              lg={{ span: 18 }}
                              md={{ span: 24 }}
                              sm={{ span: 24 }}
                              xs={{ span: 24 }}>
                              <h2>Master</h2>
                              <Form
                                    layout="vertical"
                                    key="sm"
                                    ref={this.form}
                                    {...layout}
                                    loading={this.props.loading}
                                    name="basic"
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    initialValues={{
                                          maxForSingleBet: this.master?.betLimitation?.maxForSingleBet ?? 0,
                                          maxForMixBet: this.master?.betLimitation?.maxForMixBet ?? 0,
                                          maxForThreeD: this.master?.betLimitation?.maxForThreeD ?? 0,
                                          maxForFourD:this.master?.betCommission?.maxForFourD??0,
                                          sharePercentage: this.master?.shareDetail?.sharePercentage ?? 0,
                                          threeDSharePercentage: this.master?.shareDetail?.threeDSharePercentage ?? 0,
                                          fourDSharePercentage: this.master?.shareDetail?.fourDSharePercentage ?? 0,
                                          betCommission: this.master?.singleBetCommission?.betCommission ?? 0,
                                          highBetCommission: this.master?.singleBetCommission?.highBetCommission ?? 0,
                                          // twoDCommission: this.master?.twoDCommission ?? 0,
                                          threeDCommission: this.master?.threeDCommission ?? 0,
                                          fourDCommission:this.master?.fourDCommission??0,
                                          ...this.mixBetComInitials
                                    }}
                              >
                                    <h3>Basic Info</h3>
                                    <Row gutter={20}>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="username"
                                                      label="Username"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: '3 Characters Exactly',
                                                                  max: 3,
                                                                  min: 3
                                                            },
                                                      ]}
                                                >
                                                      <Input prefix={this.props.smaster?.username} />
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="name"
                                                      label="Name"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <Input />
                                                </Form.Item>
                                          </Col>
                                    </Row>
                                    <Row gutter={20}>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="password"
                                                      label="Password"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <Input />
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="mobile"
                                                      label="Mobile"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <Input />
                                                </Form.Item>
                                          </Col>
                                    </Row>
                                    <h3>Bet Limitation</h3>
                                    <Row gutter={20}>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="maxForMixBet"
                                                      label={<label style={{ whiteSpace: 'normal' }}>Max For Mix Bet</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber style={styles.inputNumber} min={0} />
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="maxForSingleBet"
                                                      label={<label style={{ whiteSpace: 'normal' }}>Max For Single Bet</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber style={styles.inputNumber} min={0} />
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="maxForThreeD"
                                                      label={<label style={{ whiteSpace: 'normal' }}>Max For ThreeD</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  type:'number',
                                                                  message: 'minimum 10000 and maximum '+
                                                                  (this.props.smaster?.betLimitation?.maxForThreeD??10000),
                                                                  min:10000,
                                                                  max:this.props.smaster?.betLimitation?.maxForThreeD??10000
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber style={styles.inputNumber}/>
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="maxForFourD"
                                                      label={<label style={{ whiteSpace: 'normal' }}>Max For FourD</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  type:'number',
                                                                  message: 'minimum 10000 and maximum '+
                                                                  (this.props.smaster?.betLimitation?.maxForFourD??10000),
                                                                  min:10000,
                                                                  max:this.props.smaster?.betLimitation?.maxForFourD??10000
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber style={styles.inputNumber}/>
                                                </Form.Item>
                                          </Col>
                                    </Row>

                                    <h3>Share Detail</h3>
                                    <Row gutter={20}>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="sharePercentage"
                                                      label={<label style={{ whiteSpace: 'normal' }}>Share Percentage</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber
                                                            style={styles.inputNumber}
                                                            min={0}
                                                      />
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="threeDSharePercentage"
                                                      label={<label style={{ whiteSpace: 'normal' }}>Three D Share Percentage</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber
                                                            style={styles.inputNumber}
                                                            min={0}
                                                      />
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="fourDSharePercentage"
                                                      label={<label style={{ whiteSpace: 'normal' }}>Four D Share Percentage</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber
                                                            style={styles.inputNumber}
                                                            min={0}
                                                      />
                                                </Form.Item>
                                          </Col>
                                    </Row>
                                    <h3>Single Bet Commission</h3>
                                    <Row gutter={20}>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="betCommission"
                                                      label="Commission"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber
                                                            style={styles.inputNumber}
                                                            min={0}
                                                            max={this.props.smaster?.singleBetCommission.betCommission}
                                                      />
                                                </Form.Item>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}>
                                                <Form.Item
                                                      name="highBetCommission"
                                                      label={<label style={{ whiteSpace: 'normal' }}>High Commission</label>}
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'require',
                                                            },
                                                      ]}
                                                >
                                                      <InputNumber
                                                            style={styles.inputNumber}
                                                            min={0}
                                                            max={this.props.smaster?.singleBetCommission?.highBetCommission}
                                                      />

                                                </Form.Item>
                                          </Col>
                                    </Row>
                                    <Row gutter={20}>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}
                                                style={{
                                                      height: '40px',
                                                }}>
                                                <div style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: "flex-start"
                                                }}>
                                                      Tax : {this.props.smaster?.singleBetCommission.winCommission}
                                                </div>
                                          </Col>
                                          <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                md={{ span: COLUMN_WIDTH.md }}
                                                sm={{ span: COLUMN_WIDTH.sm }}
                                                xs={{ span: COLUMN_WIDTH.xs }}
                                                style={{
                                                      height: '40px',
                                                }}>
                                                <div style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: "flex-start"
                                                }}>
                                                      High Tax :  {this.props.smaster?.singleBetCommission?.highWinCommission}
                                                </div>
                                          </Col>
                                    </Row>
                                    {this.props.smaster?.twoThreeAllowed ? (
                                          <>
                                                <h3>Three Four Commission</h3>
                                                <Row gutter={20}>
                                                      {/* <Col lg={{ span: COLUMN_WIDTH.lg }}
                                                            md={{ span: COLUMN_WIDTH.md }}
                                                            sm={{ span: COLUMN_WIDTH.sm }}
                                                            xs={{ span: COLUMN_WIDTH.xs }}>
                                                            <Form.Item
                                                                  name="twoDCommission"
                                                                  label="TwoD Commission"
                                                                  rules={[
                                                                        {
                                                                              required: true,
                                                                              message: 'require',
                                                                        },
                                                                  ]}
                                                            >
                                                                  <InputNumber style={styles.inputNumber} min={0} />
                                                            </Form.Item>
                                                      </Col> */}
                                                      <Col lg={COLUMN_WIDTH.lg} md={COLUMN_WIDTH.md} sm={COLUMN_WIDTH.sm}>
                                                            <Form.Item
                                                                  name="threeDCommission"
                                                                  label={<label style={{ whiteSpace: 'normal' }}>ThreeD Commission</label>}
                                                                  rules={[
                                                                        {
                                                                              required: true,
                                                                              message: 'require',
                                                                        },
                                                                  ]}
                                                            >
                                                                  <InputNumber style={styles.inputNumber} min={0} />
                                                            </Form.Item>
                                                      </Col>
                                                      <Col lg={COLUMN_WIDTH.lg} md={COLUMN_WIDTH.md} sm={COLUMN_WIDTH.sm}>
                                                            <Form.Item
                                                                  name="fourDCommission"
                                                                  label={<label style={{ whiteSpace: 'normal' }}>FourD Commission</label>}
                                                                  rules={[
                                                                        {
                                                                              required: true,
                                                                              message: 'require',
                                                                        },
                                                                  ]}
                                                            >
                                                                  <InputNumber style={styles.inputNumber} min={0} />
                                                            </Form.Item>
                                                      </Col>
                                                </Row>
                                          </>
                                    ) : null}

                                    <h3>Mix Bet Commissions</h3>
                                    {
                                          this.props.smaster?.commissions?.map((commission, index) => {
                                                return (
                                                      <Row gutter={20} style={{ marginTop: 20 }} align="center">
                                                            <Col span={6}
                                                                  style={{
                                                                        paddingTop: '5px',
                                                                        height: '40px',
                                                                  }}>
                                                                  <div style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        display: 'flex',
                                                                        flexDirection: "column",
                                                                        gap: 10,
                                                                  }}>
                                                                        <span style={{
                                                                              whiteSpace: 'nowrap',
                                                                              textOverflow: 'ellipsis'
                                                                        }}>Match Count</span>
                                                                        <span>{commission.matchCount}</span>
                                                                  </div>
                                                            </Col>
                                                            <Col span={6}
                                                                  style={{
                                                                        paddingTop: '5px',
                                                                        height: '40px',
                                                                  }}>
                                                                  <div style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        display: 'flex',
                                                                        flexDirection: "column",
                                                                        gap: 10
                                                                  }}>
                                                                        <span>Tax</span>
                                                                        <span>{commission.winCommission}</span>
                                                                  </div>
                                                            </Col>
                                                            <Col span={12}>
                                                                  <Form.Item
                                                                        {...commmissionLayout}
                                                                        name={`betCommission${index}`}
                                                                        label="Commission"
                                                                        rules={[
                                                                              {
                                                                                    required: true,
                                                                                    message: 'require'
                                                                              },
                                                                        ]}
                                                                  >
                                                                        <InputNumber
                                                                              style={styles.percent}
                                                                              min={0}
                                                                              max={commission.betCommission}
                                                                              style={{ width: '100%' }}
                                                                        />

                                                                  </Form.Item>
                                                            </Col>

                                                      </Row>
                                                );
                                          })
                                    }
                                    <Form.Item>
                                          <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                                Create
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </Col>
                  </Row>
            );
      }

}

const styles = {
      inputNumber: {
            width: '100%'
      },
      percent: {
            width: 100
      }
}

const mapStateToProps = state => ({
      message: state.master.message,
      error: state.master.error,
      loading: state.master.loading,
      smaster: state.user.current
})

const mapDispatchToProps = dispatch => ({
      onCreate: (request) => dispatch(createMaster(request))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateMaster);