import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const updateSuspend=({
    masterId,
    suspend
})=>async dispatch=>{
    dispatch({
        type:actionTypes.UPDATE_SUSPEND_START,
    })
    const options={
        url:`/masters/${masterId}/suspend`,
        method:'PUT',
        data:{
            suspend
        }
    }
    await axios(options)
        .then(response=>dispatch({
            type:actionTypes.UPDATE_SUSPEND_SUCCESS,
            payload:response.data
        }))
        .catch(error=>dispatch({
            type:actionTypes.UPDATE_SUSPEND_FAIL,
            payload:getMessage(error)
        }))
}