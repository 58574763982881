export const ADD_SUB_SMASTER_START='ADD_SUB_SMASTER_START';
export const ADD_SUB_SMASTER_SUCCESS='ADD_SUB_SMASTER_SUCCESS';
export const ADD_SUB_SMASTER_FAIL='ADD_SUB_SMASTER_FAIL';

export const SUB_SMASTER_LIST_START='SUB_SMASTER_LIST_START';
export const SUB_SMASTER_LIST_SUCCESS='SUB_SMASTER_LIST_SUCCESS';
export const SUB_SMASTER_LIST_FAIL='SUB_SMASTER_LIST_FAIL';

export const RESET_SUB_SMASTER_PASSWORD_START='RESET_SUB_SMASTER_PASSWORD_START';
export const RESET_SUB_SMASTER_PASSWORD_SUCCESS='RESET_SUB_SMASTER_PASSWORD_SUCCESS';
export const RESET_SUB_SMASTER_PASSWORD_FAIL='RESET_SUB_SMASTER_PASSWORD_FAIL';


export const SUB_SMASTER_UPDATE_START='SUB_SMASTER_UPDATE_START';
export const SUB_SMASTER_UPDATE_SUCCESS='SUB_SMASTER_UPDATE_SUCCESS';
export const SUB_SMASTER_UPDATE_FAIL='SUB_SMASTER_UPDATE_FAIL';

export const TOGGLE_SUB_SMASTER_ADD_MODAL='TOGGLE_SUB_SMASTER_ADD_MODAL';
export const TOGGLE_SUB_SMASTER_UPDATE_MODAL='TOGGLE_SUB_SMASTER_UPDATE_MODAL';
export const TOGGLE_SUB_SMASTER_RESET_PW_MODAL='TOGGLE_SUB_SMASTER_RESET_PW_MODAL';