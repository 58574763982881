import React from 'react';
import { connect } from 'react-redux';
import { toggleUpdateModal } from '../../redux/actions/subSmaster/toggleModals';
import { updateSubSmaster } from '../../redux/actions/subSmaster/updateSubSmaster';
import { message, Modal, Form, Input } from 'antd';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class AddSubSmasterModal extends React.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate({
            id: this.props.one?.id,
            ...values
        })
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
            this.form.current.resetFields();
            this.props.onClose();
        }
    }
    render() {
        return (<Modal
            title="Update Sub Smaster"
            key={`${this.props.one?.id}update-sub-smaster`}
            centered
            visible={this.props.visible}
            okButtonProps={{ form: 'update-ss-form', key: 'submit', htmlType: 'submit' }}
            confirmLoading={this.props.loading}
            onCancel={this.props.onClose}
            okText="Save"
            loading={this.props.loading}
        >
            <Form
                {...layout}
                ref={this.form}
                style={{ margin: 25 }}
                id="update-ss-form"
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                initialValues={{
                    name:this.props.one?.name,
                    mobile:this.props.one?.mobile
                }}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Require!',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="mobile"
                    label="Mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Require!',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

            </Form>
        </Modal>);
    }
}

const mapStateToProps = state => ({
    loading: state.subSmaster.loading,
    error: state.subSmaster.error,
    message: state.subSmaster.message,
    one: state.subSmaster.one,
    visible: state.subSmaster.updateSubSmasterVisible
})

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(toggleUpdateModal()),
    onUpdate: (obj) => dispatch(updateSubSmaster(obj))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSubSmasterModal);
