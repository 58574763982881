import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      CREATE_MASTER_START,
      CREATE_MASTER_SUCCESS,
      CREATE_MASTER_FAIL,
      GET_MASTERS_START,
      GET_MASTERS_SUCCESS,
      GET_MASTERS_FAIL,
      SET_COMMISSION_TO_UPDATE,
      UPDATE_MASTER_START,
      UPDATE_MASTER_SUCCESS,
      UPDATE_MASTER_FAIL,
      TOGGLE_BASIC_INFO_UPDATE,
      TOGGLE_BET_LIMIT_UPDATE,
      TOGGLE_COMMISSION_UPDATE,
      TOGGLE_SHARE_DETAIL_UPDATE,
      TOGGLE_SINGLE_BET_COMMISSION_UPDATE,
      TOGGLE_SINGLE_BET_UPDATE,
      TOGGLE_EXTRA_COMMISSION_UPDATE,
      GET_ONE_MASTER_START,
      GET_ONE_MASTER_SUCCESS,
      GET_ONE_MASTER_FAIL,
      UPDATE_SUSPEND_START,
      UPDATE_SUSPEND_SUCCESS,
      UPDATE_SUSPEND_FAIL,
      RESET_PASSWORD_START,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAIL,
      TOGGLE_RESET_PASSWORD_MODAL,
      UPDATE_MASTER_BALANCE,
      TOGGLE_2D_COMMISSION_UPDATE,
      TOGGLE_3D_COMMISSION_UPDATE,
      TOGGLE_4D_COMMISSION_UPDATE,
} from '../actions/master/actionTypes';

const initialState = {
      loading: false,
      error: null,
      message: null,
      all: [],
      one: {
            name: null,
            username: null,
            mobile: null,
            balance: 0,
            commissions: [],
            betLimitation: {
                  maxForSingleBet: 0,
                  maxForMixBet: 0,
                  maxForThreeD: 0,
                  maxForFourD:0,
            },
            singleBetCommission: {
                  betCommission: 0,
                  highBetCommission: 0,
                  winCommission: 0,
                  highWinCommission: 0
            },
            shareDetail: {
                  sharePercentage: 0,
                  threeDSharePercentage: 0,
                  fourDSharePercentage: 0,
            },
            partnerShare: 0,
            extraCommissionAllowed: false,
            singleBetAllowed: false
      },
      commissionToUpdate: null,
      updateBasicInfoVisible: false,
      updateBetLimitVisible: false,
      updateCommissionVisible: false,
      updateShareDetailVisible: false,
      updateSingleBetCommissionVisible: false,
      updateSingleBetVisible: false,
      updateExtraCommissionVisible: false,
      resetPasswordVisible: false,
      update2dCommissionVisible: false,
      update3dCommissionVisible: false,
      update4dCommissionVisible: false,
}


const create = (state, action) => {
      return update(state, {
            loading: false,
            all: [...state.all, action.payload],
            message: 'create success'
      })
}

const updateMaster = (state, action) => {
      return update(state, {
            loading: false,
            all: state.all
                  .map(
                        sm => sm.id === action.payload.id ? action.payload : sm
                  ),
            message: 'update success',
            one: action.payload
      })
}

const setCommissionToUpdate = (state, action) => {
      return update(state, {
            commissionToUpdate: action.payload
      })
}

const getMasters = (state, action) => {
      return update(state, {
            loading: false,
            all: action.payload
      })
}

const getMaster = (state, action) => {
      return update(state, {
            loading: false,
            one: action.payload
      })
}

const updateSuspend = (state, action) => {
      return update(state, {
            loading: false,
            message: action.payload?.suspended ? 'suspended successfully' : 'unsuspended successfully',
            one: action.payload
      })
}

const resetPassword = (state, action) => {
      return update(state, {
            loading: false,
            message: 'Reset Password Successfully'
      })
}

const updateMasterBalance = (state, action) => {
      let amount = 0;
      if (action.payload?.transactionType === "OUT") {
            amount = state.one?.balance + action.payload?.amount;
      } else {
            amount = state.one?.balance - action.payload?.amount;
      }
      return update(state, {
            one: {
                  ...state.one,
                  balance: amount
            }
      })
}

const toggle4DCommissionUpdate = (state, action) => {
      return update(state, {
            update4dCommissionVisible: !state.update4dCommissionVisible
      })
}

const seniormasterReducer = (state = initialState, action) => {
      switch (action.type) {
            case CREATE_MASTER_START:
                  return loading(state, action);
            case CREATE_MASTER_SUCCESS:
                  return create(state, action);
            case CREATE_MASTER_FAIL:
                  return error(state, action);
            case GET_MASTERS_START:
                  return loading(state, action);
            case GET_MASTERS_SUCCESS:
                  return getMasters(state, action);
            case GET_MASTERS_FAIL:
                  return error(state, action);
            case UPDATE_MASTER_START:
                  return loading(state, action);
            case UPDATE_MASTER_SUCCESS:
                  return updateMaster(state, action);
            case UPDATE_MASTER_FAIL:
                  return error(state, action);
            case SET_COMMISSION_TO_UPDATE:
                  return setCommissionToUpdate(state, action);
            case TOGGLE_BASIC_INFO_UPDATE:
                  return update(state, {
                        updateBasicInfoVisible: !state.updateBasicInfoVisible
                  });
            case TOGGLE_BET_LIMIT_UPDATE:
                  return update(state, {
                        updateBetLimitVisible: !state.updateBetLimitVisible
                  });
            case TOGGLE_COMMISSION_UPDATE:
                  return update(state, {
                        updateCommissionVisible: !state.updateCommissionVisible
                  });
            case TOGGLE_SHARE_DETAIL_UPDATE:
                  return update(state, {
                        updateShareDetailVisible: !state.updateShareDetailVisible
                  });
            case TOGGLE_SINGLE_BET_COMMISSION_UPDATE:
                  return update(state, {
                        updateSingleBetCommissionVisible: !state.updateSingleBetCommissionVisible
                  });
            case TOGGLE_SINGLE_BET_UPDATE:
                  return update(state, {
                        updateSingleBetVisible: !state.updateSingleBetVisible
                  });
            case TOGGLE_EXTRA_COMMISSION_UPDATE:
                  return update(state, {
                        updateExtraCommissionVisible: !state.updateExtraCommissionVisible
                  });
            case TOGGLE_2D_COMMISSION_UPDATE:
                  return update(state, {
                        update2dCommissionVisible: !state.update2dCommissionVisible
                  });
            case TOGGLE_3D_COMMISSION_UPDATE:
                  return update(state, {
                        update3dCommissionVisible: !state.update3dCommissionVisible
                  });
            case TOGGLE_4D_COMMISSION_UPDATE:
                  return toggle4DCommissionUpdate(state, action);
            case GET_ONE_MASTER_START:
                  return loading(state, action);
            case GET_ONE_MASTER_SUCCESS:
                  return getMaster(state, action);
            case GET_ONE_MASTER_FAIL:
                  return error(state, action);
            case UPDATE_SUSPEND_START:
                  return loading(state, action);
            case UPDATE_SUSPEND_SUCCESS:
                  return updateSuspend(state, action);
            case UPDATE_SUSPEND_FAIL:
                  return error(state, action);
            case RESET_PASSWORD_START:
                  return loading(state, action);
            case RESET_PASSWORD_SUCCESS:
                  return resetPassword(state, action);
            case RESET_PASSWORD_FAIL:
                  return error(state, action);
            case TOGGLE_RESET_PASSWORD_MODAL:
                  return update(state, {
                        resetPasswordVisible: !state.resetPasswordVisible
                  });
            case UPDATE_MASTER_BALANCE:
                  return updateMasterBalance(state, action);
            default:
                  return state;
      }
}

export default seniormasterReducer;