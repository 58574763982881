import { Layout, Button } from 'antd';
import React from 'react';
import brand from '../assets/brand.svg';
import { withRouter } from 'react-router-dom';
import UpdateBasicInfoModal from '../components/Master/UpdateBasicInfoModal';
import UpdateShareDetailModal from '../components/Master/UpdateShareDetailModal';
import UpdateBetLimitationModal from '../components/Master/UpdateBetLimitationModal';
import UpdateSMCommissionModal from '../components/Master/UpdateSMCommissionModal';
import CreateTransactionModal from '../components/Master/CreateTransactionModal';
import UpdateSingleBetCommissionModal from '../components/Master/UpdateSingleBetCommissionModal';
import { getCurrentUser } from '../redux/actions/user/getCurrentUser';
import { connect } from 'react-redux';
import ResetPasswordModal from '../components/Master/ResetPasswordModal';
import AddSubSmasterModal from '../components/SubSmaster/AddSubSmasterModal';
import UpdateSubSmasterModal from '../components/SubSmaster/UpdateSubSmasterModal';
import ResetSubSmasterPasswordModal from '../components/SubSmaster/ResetSubSmasterPasswordModal';
import Agreement from '../components/Agreement/Agreement';
import { getAgreement } from '../redux/actions/agreement/getAgreement';
import DefaultMenu from './DefaultMenu';
import MobileMenu from './MobileMenu';
import Update2DCommissionModal from '../components/Master/Update2DCommissionModal';
import Update3DCommissionModal from '../components/Master/Update3DCommissionModal';
import {
   MenuUnfoldOutlined,
   MenuFoldOutlined
} from '@ant-design/icons';
import Update4DCommissionModal from '../components/Master/Update4DCommissionModal';

const { Header, Content, Sider } = Layout;

class App extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         visible: false,
         isMobile: false,
         isTablet: false,
      }
      this.toggleMenu = this.toggleMenu.bind(this);
      this.headerRef=React.createRef();
   }
   async componentDidMount() {
      const mobileQuery = window.matchMedia(`(max-width: 480px)`);
      const tabletQuery = window.matchMedia(`(min-width: 481px) 
      and (max-width: 1024px)`);
      mobileQuery.onchange = (e) => {
         console.log('max-width changed in mobile');
         this.setState(prevState => {
            return {
               ...prevState,
               isMobile: e.matches
            }
         })
      }
      tabletQuery.onchange = (e) => {
         console.log('max-width changed in tablet')
         this.setState(prevState => {
            return {
               ...prevState,
               isTablet: e.matches
            }
         })
      }
      this.setState(prevState => {
         return {
            ...prevState,
            isTablet: tabletQuery.matches,
            isMobile: mobileQuery.matches,
         }
      })
      await this.props.onGetAgreement();
      await this.props.onGetCurrentUser();
   }
   toggleMenu() {
      this.setState({ visible: !this.state.visible })
   }
   render() {
      const { isMobile, isTablet } = this.state;
      const computedHeight = `calc(100vh - ${this.headerRef?.current?.offsetHeight ?? 0}px)`;
      return (
         <Layout style={{ minHeight: '100vh' }}>
            <Header>
               <div 
                  ref={this.headerRef}
                  style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#B5BAC7',
                  fontSize: '16px'
               }}>
                  <span>
                     <img src={brand} alt="555Mix" style={{
                        marginRight: '10px',
                        width: 90
                     }} /> SMaster
                  </span>
                  {(!isMobile) ? <div style={{ marginLeft: 'auto' }}>Balance: {this.props.balance}</div> : null}
                  {(isMobile && this.props.token) ? <Button style={styles.toggleButton}
                     onClick={this.toggleMenu}>
                     {this.state.visible ? <MenuFoldOutlined /> :
                        <MenuUnfoldOutlined />}
                  </Button> : null}
               </div>
            </Header>
            <Layout>
               <UpdateBasicInfoModal />
               <UpdateShareDetailModal />
               <UpdateBetLimitationModal />
               <UpdateSMCommissionModal />
               <CreateTransactionModal />
               <UpdateSingleBetCommissionModal />
               <ResetPasswordModal />
               <AddSubSmasterModal />
               <UpdateSubSmasterModal />
               <ResetSubSmasterPasswordModal />
               <Update2DCommissionModal />
               <Update3DCommissionModal />
               <Update4DCommissionModal />
               <Agreement />
               {(isMobile && this.props.token) ? <MobileMenu
                  toggleMenu={this.toggleMenu}
                  visible={this.state.visible} /> : isTablet ?
                  <Sider 
                        style={{
                              boxSizing:'border-box',
                              overflow: 'auto',
                              height: computedHeight
                        }}
                        key="tablet-sider" 
                        defaultCollapsed={true} 
                        collapsible>
                     <DefaultMenu />
                  </Sider> : <Sider 
                                    style={{
                                          boxSizing:'border-box',
                                          overflow: 'auto',
                                          height: computedHeight
                                    }}
                                    key="default-sider" 
                                    collapsible>
                     <DefaultMenu />
                  </Sider>}
               <Content style={{
                  margin: '0',
                  minHeight: 360,
                  padding: 24,
                  background: '#f7f7ff',
                  overflow: 'auto',
                  height: computedHeight,
                  boxSizing:'border-box'
               }}>
                  {this.props.children}
               </Content>
            </Layout>
         </Layout>
      );
   }
}


const styles = {
   toggleButton: {
      margin: 0,
      marginLeft: 'auto',
      padding: 0,
      fontSize: '18px',
      backgroundColor: 'transparent',
      border: 0,
      outline: 'none',
      color: 'white'
   }
}

const mapStateToProps = state => ({
   balance: state.user.current?.balance,
   token: state.auth.token,
})

const mapDispatchToProps = dispatch => ({
   onGetCurrentUser: () => dispatch(getCurrentUser()),
   onGetAgreement: () => dispatch(getAgreement())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));