import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterTransactionDetail } from '../../redux/actions/transaction/getMasterTransactionDetail';
import { message, Table, Row, Col } from 'antd';
import moment from 'moment';

const columns = [
   {
      title: 'Date',
      dataIndex: 'transactionTime',
      key: 'transactionTime',
      render: (text, record) => moment(record.transactionTime).locale('my').format('YYYY-MM-DD hh:mm a')
   },
   {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
   },
   {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType'
   },
   {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record) => `${record.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
   },
   {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      align: 'right',
      render: (text, record) => `${record.balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
   }
];

export default function MasterTransactionDetail() {
   const { id, date } = useParams();
   const dispatch = useDispatch();
   const { error, loading, masterDetailTransaction } = useSelector(state => state.transaction);
   useEffect(async () => {
      await dispatch(getMasterTransactionDetail(id, date));
      if (error) {
         message.error(error);
      }
   }, []);

   return <Row>
      <Col span={24} style={{
         width: '100%',
         overflowX: 'scroll',
      }}>
         <Table columns={columns} dataSource={masterDetailTransaction} loading={loading} />
      </Col>
   </Row>
}