import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAddModal, toggleResetPassword, toggleUpdateModal } from '../../redux/actions/subSmaster/toggleModals';
import { getSubSmasters } from '../../redux/actions/subSmaster/getSubSmasters';
import { Table, PageHeader, Row, Col, Button, message as Noti } from 'antd';
import {
   PlusSquareOutlined,
   RetweetOutlined,
   EditOutlined
} from '@ant-design/icons';

export default function SubSmasters() {
   const dispatch = useDispatch();
   const { loading, error, all } = useSelector(state => state.subSmaster);
   useEffect(async () => {
      await dispatch(getSubSmasters());
      if (error) {
         Noti.error(error);
      }
   }, []);
   const columns = [
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name'
      },
      {
         title: 'ID',
         dataIndex: 'username',
         key: 'username'
      },
      {
         title: 'Mobile',
         dataIndex: 'mobile',
         key: 'mobile'
      },
      {
         title: 'Password',
         key: 'password',
         align: 'center',
         render: (text, record) => <RetweetOutlined onClick={() => dispatch(toggleResetPassword(record))} />
      },
      {
         title: 'Action',
         key: 'action',
         align: 'center',
         render: (text, record) => <EditOutlined onClick={() => dispatch(toggleUpdateModal(record))} />
      }
   ]
   return <Row>
      <PageHeader
         style={{
            display: 'flex',
            width:'100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            border: '1px solid rgb(235, 237, 240)'
         }}
         title="Sub Smasters"
      >
         <Button
            icon={<PlusSquareOutlined />}
            style={{ background: '#3071b9', color: 'white' }}
            onClick={() => dispatch(toggleAddModal())}
         />
      </PageHeader>
      <Col span={24} style={{
         width: '100%',
         overflowX: 'scroll',
      }}>
         <Table columns={columns} dataSource={all} loading={loading} />
      </Col>
   </Row>
}

