import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const resetPassword=({newPassword,id})=>async dispatch=>{
    dispatch({
        type:actionTypes.RESET_PASSWORD_START,
    });
    const options={
        url:`/masters/${id}/resetPassword/`,
        method:'POST',
        data:{
            newPassword
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.RESET_PASSWORD_SUCCESS,
            }))
            .catch(error=>dispatch({
                type:actionTypes.RESET_PASSWORD_FAIL,
                payload:getMessage(error)
            }))
}