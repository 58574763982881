import { Tabs } from 'antd';
import MasterDetail from './MasterDetail';
import MasterTransactions from './MasterTransactions';

const Dashboard = () => {
    const { TabPane } = Tabs;
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Master Detail" key="1">
                <MasterDetail />
            </TabPane>
            <TabPane tab="Transaction" key="2">
                <MasterTransactions />
            </TabPane>
        </Tabs>
    );
}

export default Dashboard;