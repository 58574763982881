import React from 'react';
import {Col, Input, message, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getMasters} from '../../redux/actions/master/getMasters';
import {EyeOutlined} from '@ant-design/icons';

class MasterList extends React.Component {
    state = {
        searchKey: "",
        searchResult: []
    }

    async componentDidMount() {
        await this.props.onGetMasters();
    }

    componentDidUpdate() {
        if (this.props.error) {
            message.error(this.props.error);
        }
    }

    onSearch = ({target: {value}}) => {
        const result = this.props.masters?.filter(el => `${el.username}`.toLowerCase().includes(value.toLowerCase())
            || `${el.name}`.toLowerCase().includes(value.toLowerCase()));
        this.setState(oldState => ({
            ...oldState,
            searchResult: result,
            searchKey: value
        }))
    }

    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase(),
                sortDirections: ['descend']
            },
            {
                title: 'Username',
                dataIndex: 'username',
                key: 'username'
            },
            {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance'
            },
            {
                title: 'Detail',
                render: (text, record) => (<EyeOutlined onClick={
                    () => this.props.history.push(`/masters/${record.id}`)
                }/>)
            }
        ]
        return (<>
            <Row gutter={[16, 16]}>
                <Col span={{lg: 6, md: 10, sm: 20}}>
                    <Input.Search
                        style={{width: '220px'}}
                        placeholder="Search"
                        onChange={this.onSearch}
                        enterButton/>
                </Col>
                <Col span={24} style={{
                    width: '100%',
                    overflowX: 'scroll',
                }}>
                    <Table columns={columns} dataSource={this.state.searchKey ?
                        this.state.searchResult : this.props.masters}
                           loading={this.props.loading}/>
                </Col>
            </Row>
        </>)
    }
}

const mapStateToProps = state => ({
    loading: state.master.loading,
    masters: state.master.all,
    error: state.master.error
})

const mapDispatchToProps = dispatch => ({
    onGetMasters: () => dispatch(getMasters())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterList));