import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getTwoDReport=()=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_2D_REPORT_START
    })
    await axios.get("/twoDReports/smaster")
            .then(response=>dispatch({
                type:actionTypes.GET_2D_REPORT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.GET_2D_REPORT_FAIL,
                payload:getMessage(error)
            }))
}