export const getSelectedKey = ({ pathname }) => {
      if (pathname.includes("/masters/") && /\d/.test(pathname)) {
            return ['13'];
      }
      if (pathname.includes("/transactions/")) {
            return ['22'];
      }
      if (pathname.includes("/bet_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      if (pathname.includes("/2d_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      if (pathname.includes("/3d_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      if (pathname.includes("/4d_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      switch (pathname) {
            case "/":
                  return ['22'];
            case "/lsport":
                  return ['2'];
            case "/leagues":
                  return ['3'];
            case "/teams":
                  return ['4'];
            case "/masters/new":
                  return ['10'];
            case "/masters":
                  return ['13'];
            case "/collection_report":
                  return ['16'];
            case "/master_report":
                  return ['18'];
            case "/account":
                  return ['20'];
            case "/sub_smasters":
                  return ['23'];
            case "/2d_report":
                  return ['19'];
            case "/3d_report":
                  return ['21'];
            case "/4d_report":
                  return ['24'];
            case "/threeDCollections":
                  return ['7'];
            case "/twoDCollections":
                  return ['8'];
            default:
                  return ['2'];
      }
}