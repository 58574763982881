import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const addSubSmaster=({
    username,
    name,
    password,
    mobile
})=>async dispatch=>{
    dispatch({
        type:actionTypes.ADD_SUB_SMASTER_START
    })
    const options={
        url:'/auth/subsmaster/signup',
        method:'POST',
        data:{
            username,
            name,
            password,
            mobile
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.ADD_SUB_SMASTER_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.ADD_SUB_SMASTER_FAIL,
                payload:getMessage(error)
            }))
}