import * as actionTypes from './actionTypes';

export const toggleAddModal=()=>({
    type:actionTypes.TOGGLE_SUB_SMASTER_ADD_MODAL
})

export const toggleUpdateModal=(record)=>({
    type:actionTypes.TOGGLE_SUB_SMASTER_UPDATE_MODAL,
    payload:record
})

export const toggleResetPassword=(record)=>({
    type:actionTypes.TOGGLE_SUB_SMASTER_RESET_PW_MODAL,
    payload:record
})