import { getSelectedKey } from './getSelectedKey';
import { useLocation, Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import {
      LogoutOutlined,
      TeamOutlined,
      AreaChartOutlined,
      ControlOutlined,
      DashboardOutlined,
} from '@ant-design/icons';

DefaultMenu.defaultProps = {
      style: {},
      theme: "dark"
}

function DefaultMenu(props) {
      const { SubMenu } = Menu;
      const location = useLocation();
      const twoThreeAllowed = useSelector(state => state.user?.current?.twoThreeAllowed);
      return (<Menu
            theme={props.theme}
            defaultSelectedKeys={getSelectedKey(location)}
            mode="inline"
            style={props.style}
      >
            <Menu.Item key="22" icon={<DashboardOutlined />}>
                  <Link to="/">Dashboard</Link>
            </Menu.Item>
            <SubMenu key="sub3" icon={<TeamOutlined />} title="Master">
                  <Menu.Item key="10">
                        <Link to="/masters/new">Create</Link>
                  </Menu.Item>
                  <Menu.Item key="13">
                        <Link to="/masters">List</Link>
                  </Menu.Item>
            </SubMenu>
            <Menu.Item key="23" icon={<TeamOutlined />}>
                  <Link to="/sub_smasters">Sub Smasters</Link>
            </Menu.Item>
            <SubMenu key="sub5" icon={<AreaChartOutlined />} title="Report">
                  <Menu.Item key="16">
                        <Link to="/collection_report">Daily</Link>
                  </Menu.Item>
                  <Menu.Item key="18">
                        <Link to="/master_report">Master</Link>
                  </Menu.Item>
                  {
                        twoThreeAllowed ? ([
                              // <Menu.Item key="19">
                              //    <Link to="/2d_report">Two D</Link>
                              // </Menu.Item>,
                              <Menu.Item key="21">
                                    <Link to="/3d_report">Three D</Link>
                              </Menu.Item>,
                              <Menu.Item key="24">
                                    <Link to="/4d_report">Four D</Link>
                              </Menu.Item>]) : null
                  }
            </SubMenu>
            <SubMenu key="sub000" icon={<ControlOutlined />} title="Account">
                  <Menu.Item key="20">
                        <Link to="/account">Change Password</Link>
                  </Menu.Item>
            </SubMenu>
            <Menu.Item key="100" icon={<LogoutOutlined />}>
                  <Link to="/logout" >Logout</Link>
            </Menu.Item>
      </Menu>);
}

export default DefaultMenu;