import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';


export const getOneMaster=(id)=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_ONE_MASTER_START
    })
    await axios.get(`/masters/${id}`)
            .then(response=>dispatch({
                type:actionTypes.GET_ONE_MASTER_SUCCESS,
                payload:(console.log(response.data),response.data)
            }))
            .catch(error=>dispatch({
                type:actionTypes.GET_ONE_MASTER_FAIL,
                payload:getMessage(error)
            }))
}