import React, { useEffect } from 'react';
import { Col, message, Row, Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getBetReport } from '../../redux/actions/report/getBetReport';
import currency from 'currency.js';
import { ParseIntAndFormat } from "./ParseIntAndFormat";

const BetReport = ({
      loading,
      error,
      onGet,
      bets,
      type
}) => {
      const { date, memberName } = useParams();
      const history = useHistory();
      useEffect(async () => {
            await onGet({ date, memberName, type })
            if (error) {
                  message.error(error);
            }
      }, [])
      const { Column, ColumnGroup } = Table;
      const pushHelper = (route) => {
            history.push(route);
      }

      const handlePush = (id) => {
            switch (type) {
                  case "SOCCER":
                        return pushHelper(`/bet_detail/${id}`);
                  case "TWOD":
                        return pushHelper(`/2d_detail/${id}`);
                  case "THREED":
                        return pushHelper(`/3d_detail/${id}`);
                  case "FOURD":
                        return pushHelper(`/4d_detail/${id}`);
                  default:
                        message.info('Refresh and try again');
            }
      }
      return <>
            <Row>
                  <Col span={24} style={{
                        width: '100%',
                        overflowX: 'scroll',
                  }}>
                        <Table
                              bordered
                              dataSource={bets}
                              loading={loading}
                        >
                              <Column
                                    title="id"
                                    dataIndex="id"
                                    key="id"
                              />
                              <ColumnGroup title="Company">
                                    <Column
                                          title="Bet"
                                          dataIndex="smasterBetAmount"
                                          key="smasterBetAmount"
                                          align="right"
                                          render={(text, record) => ParseIntAndFormat(record.smasterBetAmount ?? 0)}
                                    />
                                    <Column
                                          title="Commission"
                                          dataIndex="smasterCommissionAmount"
                                          key="smasterCommissionAmount"
                                          align="right"
                                          render={(text, record) => ParseIntAndFormat(record.smasterCommissionAmount ?? 0)}
                                    />
                                    <Column
                                          title="W/L"
                                          render={(text, {
                                                smasterBetAmount,
                                                smasterWinAmount,
                                                smasterCommissionAmount
                                          }) => {
                                                let sum = currency(smasterBetAmount)
                                                      .subtract(currency(smasterWinAmount))
                                                      .subtract(currency(smasterCommissionAmount))
                                                      .value;
                                                return ParseIntAndFormat(sum);
                                          }
                                          }
                                    />
                              </ColumnGroup>
                              <ColumnGroup title="Senior Master">
                                    <Column
                                          title="Bet"
                                          dataIndex="masterBetAmount"
                                          key="masterBetAmount"
                                          align="right"
                                          render={(text, record) => ParseIntAndFormat(record.masterBetAmount ?? 0)}
                                    />
                                    <Column
                                          title="Commission"
                                          dataIndex="masterCommissionAmount"
                                          key="masterCommissionAmount"
                                          align="right"
                                          render={(text, record) => ParseIntAndFormat(record.masterBetAmount ?? 0)}
                                    />
                                    <Column
                                          title="W/L"
                                          render={(text, {
                                                masterBetAmount,
                                                masterWinAmount,
                                                masterCommissionAmount
                                          }) => {
                                                let sum = currency(masterBetAmount)
                                                      .subtract(currency(masterWinAmount))
                                                      .subtract(currency(masterCommissionAmount))
                                                      .value;
                                                return ParseIntAndFormat(sum);
                                          }
                                          }
                                    />
                              </ColumnGroup>

                              <Column
                                    title="Detail"
                                    key="detail"
                                    render={(text, record) => <EyeOutlined
                                          onClick={() => handlePush(record.id)}
                                    />}
                              />
                        </Table>
                  </Col>
            </Row>
      </>
}

const mapStateToProps = state => ({
      loading: state.report.loading,
      bets: state.report.bets,
      error: state.report.error,
      type: state.report.type
})

const mapDispatchToProps = dispatch => ({
      onGet: (obj) => dispatch(getBetReport(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BetReport);