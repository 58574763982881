import React from 'react';
import {Col, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {getAgentReport} from '../../redux/actions/report/getAgentReport';
import {withRouter} from 'react-router-dom';
import currency from 'currency.js';
import {EyeOutlined} from '@ant-design/icons';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class AgentReport extends React.Component {
    componentDidMount() {
        const {
            match: {
                params: {
                    date,
                    masterName
                }
            }
        } = this.props;
        this.props.onGetReport({date, masterName, type: this.props.type});
    }

    render() {
        const {Column, ColumnGroup} = Table;
        return (
            <Row>
                <Col span={24} style={{
                    width: '100%',
                    overflowX: 'scroll',
                }}>
                    <Table
                        bordered
                        loading={this.props.loading}
                        dataSource={this.props.agents}>
                        <Column title="Agent Name" dataIndex="agentName" key="agentName" align="center"/>
                        <ColumnGroup title="Company">
                            <Column title="Bet"
                                    render={(text,record)=>ParseIntAndFormat(record.smasterBetAmount??0)}
                                    dataIndex="smasterBetAmount" key="smasterBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="smasterCommissionAmount"
                                    render={(text,record)=>ParseIntAndFormat(record.smasterCommissionAmount??0)}
                                    key="smasterCommissionAmount"
                                    align="right"/>
                            <Column
                                title="W/L"
                                key="p_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.smasterBetAmount)
                                        .subtract(currency(record.smasterWinAmount))
                                        .subtract(currency(record.smasterCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <ColumnGroup title="Senior Master">
                            <Column title="Bet" dataIndex="masterBetAmount"
                                    render={(text,record)=>ParseIntAndFormat(record.masterBetAmount??0)}
                                    key="masterBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="masterCommissionAmount"
                                    render={(text,record)=>ParseIntAndFormat(record.masterCommissionAmount??0)}
                                    key="masterCommissionAmount"
                                    align="right"/>
                            <Column
                                title="W/L"
                                key="s_win_lose"
                                render={(text, record) => {
                                   let sum=currency(record.masterBetAmount)
                                       .subtract(currency(record.masterWinAmount))
                                       .subtract(currency(record.masterCommissionAmount))
                                       .value;
                                   return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <Column title="Action" key="action" align="center" render={
                            (text, record) => <EyeOutlined onClick={
                                () => this.props.history.push(`/report/daily/${this.props.match.params.date}/agents/${record.agentName}/members`)
                            }/>
                        }/>
                    </Table>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    error: state.report.error,
    agents: state.report.agents,
    type: state.report.type
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (obj) => dispatch(getAgentReport(obj)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgentReport));