import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import * as actions from '../redux/actions/auth/auth';
import Login from '../screens/Login';
import Logout from '../screens/Logout';
import App from './App';
import CreateMaster from '../components/Master/CreateMaster';
import MasterList from '../components/Master/MasterList';
import TabContainer from '../components/Master/TabContainer';
import CollectionReport from '../components/Report/CollectionReport';
import BetDetail from '../components/BetPattern/BetDetail';
import MasterReport from '../components/Report/MasterReport';
import AgentReport from '../components/Report/AgentReport';
import MemberReport from '../components/Report/MemberReport';
import BetReport from '../components/Report/BetReport';
import MainMasterReport from '../components/Report/MainMasterReport';
import ChangePassword from '../components/Account/ChangePassword';
import Dashboard from '../components/Dashboard/Dashboard';
import TransactionDetail from '../components/Dashboard/TransactionDetail';
import MasterTransactionDetail from '../components/Master/MasterTransactionDetail';
import SubSmasters from '../components/SubSmaster/SubSmasters';
import TwoDReport from '../components/Report/TwoDReport';
import ThreeDReport from '../components/Report/ThreeDReport';
import ThreeDDetail from '../components/BetPattern/ThreeDDetail';
import TwoDDetail from '../components/BetPattern/TwoDDetail';
import FourDReport from '../components/Report/FourDReport';
import FourDDetail from '../components/BetPattern/FourDDetail';

import { getCurrentUser } from '../redux/actions/user/getCurrentUser';

class ConfigRoute extends React.Component {
      async componentDidMount() {
            await this.props.onAuthStateCheck();
            await this.props.onGetCurrentUser();
            
      }
      render() {
            return (<>
                  <Switch>
                        <PrivateRoute exact path="/">
                              <App>
                                    <Dashboard />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/transactions/:date">
                              <App>
                                    <TransactionDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/masters/new">
                              <App>
                                    <CreateMaster />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/masters">
                              <App>
                                    <MasterList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/masters/:id">
                              <App>
                                    <TabContainer />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/masters/:id/:date">
                              <App>
                                    <MasterTransactionDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/collection_report">
                              <App>
                                    <CollectionReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/smasters/:smasterName/masters">
                              <App>
                                    <MasterReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/masters/:masterName/agents">
                              <App>
                                    <AgentReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/agents/:agentName/members">
                              <App>
                                    <MemberReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/members/:memberName/bets">
                              <App>
                                    <BetReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/master_report">
                              <App>
                                    <MainMasterReport />
                              </App>
                        </PrivateRoute>
                        {this.props.currentUser?.twoThreeAllowed ? ([
                              <PrivateRoute path="/2d_report">
                                    <App>
                                          <TwoDReport />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/3d_report">
                                    <App>
                                          <ThreeDReport />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/4d_report">
                              <App>
                                  <FourDReport />
                              </App>
                          </PrivateRoute>,
                              <PrivateRoute path="/2d_detail/:id">
                                    <App>
                                          <TwoDDetail />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/3d_detail/:id">
                                    <App>
                                          <ThreeDDetail />
                                    </App>
                              </PrivateRoute>,
                              <PrivateRoute path="/4d_detail/:id">
                              <App>
                                    <FourDDetail />
                              </App>
                        </PrivateRoute>
                        ]) : null}
                        <PrivateRoute path="/bet_detail/:id">
                              <App>
                                    <BetDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/account">
                              <App>
                                    <ChangePassword />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/sub_smasters">
                              <App>
                                    <SubSmasters />
                              </App>
                        </PrivateRoute>
                        <Route path="/login">
                              <Login />
                        </Route>
                        <Route path="/logout">
                              <Logout />
                        </Route>
                  </Switch>
            </>);
      }
}

const mapStateToProps = state => ({
      currentUser: state.user.current
})

const mapDispatchToProps = dispatch => ({
      onAuthStateCheck: () => dispatch(actions.authCheckState()),
      onGetCurrentUser: () => dispatch(getCurrentUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfigRoute));