import React from 'react';
import {Button, Col, DatePicker, Form, message, Row, Select, Table} from 'antd';
import {connect} from 'react-redux';
import {getMainMasterReport} from '../../redux/actions/report/getMainMasterReport';
import {setBetType} from '../../redux/actions/report/setBetType'
import currency from 'currency.js';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class MainMasterReport extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        isMobile: false,
    }

    componentDidMount() {
        const query = window.matchMedia(`(max-width: 480px)`);
        query.onchange = (e) => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    isMobile: e.matches
                }
            })
        }
        this.setState({
            isMobile: query.matches
        })
    }

    dateHandler = (value, dateString) => {
        this.setState(prevState => {
            return {
                ...prevState,
                startDate: dateString[0],
                endDate: dateString[1]
            }
        });
    }
    onFinish = async (values) => {
        const {startDate, endDate} = this.state;
        await this.props.onGetReport({startDate, endDate, type: this.props.type});
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onFinishFailed = (errors) => {
        console.warn(errors);
    }

    render() {
        const {RangePicker} = DatePicker;
        const {Column, ColumnGroup} = Table;
        const {isMobile} = this.state;
        const {Option} = Select;
        return (
            <Row gutter={[16, 10]}>
                <Col span={{lg: 12, md: 12, sm: 24}}>
                    <Form
                        name="dates"
                        layout={isMobile ? "horizontal" : "inline"}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        {this.props.smaster?.twoThreeAllowed ? (
                            <Form.Item>
                                <Select
                                    defaultValue={this.props.type}
                                    onChange={(value) => this.props.onSetBetType(value)}>
                                    <Option value="SOCCER">Soccer</Option>
                                    {/* <Option value="TWOD">Two D</Option> */}
                                    <Option value="THREED">Three D</Option>
                                    <Option value="FOURD">Four D</Option>
                                </Select>
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            name="dates"
                            validateStatus="warning"
                        >
                            <RangePicker onChange={this.dateHandler} format="YYYY-MM-DD"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Load
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24} style={{
                    width: '100%',
                    overflowX: 'scroll',
                }}>
                    <Table
                        bordered
                        loading={this.props.loading}
                        dataSource={this.props.master}
                        pagination={false}
                        summary={
                            (data) => {
                                let masterBetAmount = currency(0);
                                let masterCommissionAmount = currency(0);
                                let masterWinLoseAmount = currency(0);
                                let smasterBetAmount = currency(0);
                                let smasterCommissionAmount = currency(0);
                                let smasterWinLoseAmount = currency(0);
                                data?.map(obj => {
                                    masterBetAmount = masterBetAmount.add(currency(obj.masterBetAmount));
                                    masterCommissionAmount = masterCommissionAmount.add(currency(obj.masterCommissionAmount));
                                    masterWinLoseAmount = masterWinLoseAmount.add(currency(obj.masterBetAmount)
                                        .subtract(currency(obj.masterWinAmount))
                                        .subtract(currency(obj.masterCommissionAmount)));
                                    smasterBetAmount = smasterBetAmount.add(currency(obj.smasterBetAmount));
                                    smasterCommissionAmount = smasterCommissionAmount.add(currency(obj.smasterCommissionAmount));
                                    smasterWinLoseAmount = smasterWinLoseAmount.add(currency(obj.smasterBetAmount)
                                        .subtract(currency(obj.smasterWinAmount))
                                        .subtract(currency(obj.smasterCommissionAmount)));
                                });
                                return <Table.Summary.Row style={{textAlign: 'right'}}>
                                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(smasterBetAmount.value)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(smasterCommissionAmount.value)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(smasterWinLoseAmount.value)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(masterBetAmount.value)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(masterCommissionAmount.value)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{ParseIntAndFormat(masterWinLoseAmount.value)}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            }
                        }
                    >
                        <Column title="Master Name"
                                sorter={(a, b) => a.masterName?.toLowerCase() > b.masterName?.toLowerCase()}
                                sortDirections={['descend']}
                                dataIndex="masterName"
                                key="masterName" align="center"/>

                        <ColumnGroup title="Company">
                            <Column title="Bet" dataIndex="smasterBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.smasterBetAmount ?? 0)}
                                    key="smasterBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="smasterCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.smasterCommissionAmount ?? 0)}
                                    key="smasterCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="s_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.smasterBetAmount)
                                        .subtract(currency(record.smasterWinAmount))
                                        .subtract(currency(record.smasterCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <ColumnGroup title="Senior Master">
                            <Column title="Bet" dataIndex="masterBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.masterBetAmount ?? 0)}
                                    key="masterBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="masterCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.masterCommissionAmount ?? 0)}
                                    key="masterCommissionAmount" align="right"/>
                            <Column
                                title="W/L"
                                key="p_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.masterBetAmount)
                                        .subtract(currency(record.masterWinAmount))
                                        .subtract(currency(record.masterCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                    </Table>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    error: state.report.error,
    master: state.report.master,
    type: state.report.type,
    smaster: state.user.current
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (dates) => dispatch(getMainMasterReport(dates)),
    onSetBetType: (type) => dispatch(setBetType(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMasterReport);