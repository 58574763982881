import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const resetPassword=({
    id,
    password
})=>async dispatch=>{
    console.log('pw',password)
    dispatch({
        type:actionTypes.RESET_SUB_SMASTER_PASSWORD_START
    })
    const options={
        url:`/subsmasters/${id}/resetPassword`,
        method:'POST',
        data:{
            newPassword:password
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.RESET_SUB_SMASTER_PASSWORD_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.RESET_SUB_SMASTER_PASSWORD_FAIL,
                payload:getMessage(error)
            }))
}