import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getSubSmasters=()=>async dispatch=>{
    dispatch({
        type:actionTypes.SUB_SMASTER_LIST_START
    })
    await axios.get('/subsmasters')
            .then(response=>dispatch({
                type:actionTypes.SUB_SMASTER_LIST_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.SUB_SMASTER_LIST_FAIL,
                payload:getMessage(error)
            }))
}